import React, { useState, useEffect } from 'react';
import './MainPage.css'
import axios from 'axios';

function ExchangePage( { buttonId, onGoBack } ) {

    const [exchangeData, setExchangeData] = useState([]);

    useEffect(() => {
        axios
          .post(`http://207.148.85.91:5000/api/Options_${buttonId}`, {"count": 200})
          .then((response) => {
            setExchangeData(response.data);
          })
          .catch((error) => {
            console.log(error);
            });
    }, [buttonId]);

    return (
        <div className="main-container">
            <h1>{buttonId}</h1>
            <div className="window-container exchange-container">
                <button className='return' onClick={onGoBack}></button>
                <div className='window exchange-window'>
                    <h3>Data</h3>
                    {exchangeData.map((item, index) => (
                        <div key={index} className="text-field">{item.text}</div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ExchangePage
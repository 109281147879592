import React, { useState, useEffect } from 'react';
import './MainPage.css';
import axios from 'axios';

function MainPage({ onSwitchPage }) {

    const [okxData, setOkxData] = useState([]);
    const [bybitData, setBybitData] = useState([]);
    const [deribitData, setDeribitData] = useState([]);
    const [gateIoData, setGateIoData] = useState([]);

    const handleClick = (e) => {
        onSwitchPage(e.target.id);
    };

    const fetchData = () => {
        axios
        .post("http://207.148.85.91:5000/api/Options_OKX", {"count": 5})
        .then((response) => {
          setOkxData(response.data);
        })
        .catch((error) => {
          console.log(error);
          });
      axios
          .post("http://207.148.85.91:5000/api/Options_Deribit", {"count": 5})
          .then((response) => {
          setDeribitData(response.data);
          })
          .catch((error) => {
          console.log(error);
          });
      axios
          .post("http://207.148.85.91:5000/api/Options_Bybit", {"count": 5})
          .then((response) => {
          setBybitData(response.data);
          })
          .catch((error) => {
          console.log(error);
          });
      axios
          .post("http://207.148.85.91:5000/api/Options_GateIo", {"count": 5})
          .then((response) => {
            setGateIoData(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            fetchData();
        }, 10000)
        return () => clearInterval(interval)
    }, []);

    return (
        <div className="main-container">
        <h1>Опционы - Арбитраж</h1>
            <div className="window-container">
                <div className="window okx">
                    <h3>OKX</h3>
                    {okxData.map((item, index) => {
                        const splitText = item.text.split(' ');
                        const time = splitText[splitText.length - 1];
                        const message = splitText.slice(0, splitText.length - 1).join(' ');

                        return (
                            <div key={index} className="text-field">
                            {message} <span style={{ color: 'green' }}>{time}</span>
                            </div>
                        );
                    })}
                    <button className='btn-more' id='OKX' onClick={handleClick}>Загрузить больше</button>
                </div>

                <div class="window bybit">
                    <h3>Bybit</h3>
                    {bybitData.map((item, index) => {
                        const splitText = item.text.split(' ');
                        const time = splitText[splitText.length - 1];
                        const message = splitText.slice(0, splitText.length - 1).join(' ');

                        return (
                            <div key={index} className="text-field">
                            {message} <span style={{ color: 'green' }}>{time}</span>
                            </div>
                        );
                    })}
                    <button className='btn-more' id='Bybit' onClick={handleClick}>Загрузить больше</button>
                </div>

                <div class="window deribit">
                    <h3>Deribit</h3>
                    {deribitData.map((item, index) => {
                        const splitText = item.text.split(' ');
                        const time = splitText[splitText.length - 1];
                        const message = splitText.slice(0, splitText.length - 1).join(' ');

                        return (
                            <div key={index} className="text-field">
                            {message} <span style={{ color: 'green' }}>{time}</span>
                            </div>
                        );
                    })}
                    <button className='btn-more' id='Deribit' onClick={handleClick}>Загрузить больше</button>
                </div>

                <div class="window gateio">
                    <h3>GateIo</h3>
                    {gateIoData.map((item, index) => {
                        const splitText = item.text.split(' ');
                        const time = splitText[splitText.length - 1];
                        const message = splitText.slice(0, splitText.length - 1).join(' ');

                        return (
                            <div key={index} className="text-field">
                            {message} <span style={{ color: 'green' }}>{time}</span>
                            </div>
                        );
                    })}
                    <button className='btn-more' id='GateIo' onClick={handleClick}>Загрузить больше</button>
                </div>
            </div>
        </div>
    );
}

export default MainPage;
import React, { useState } from 'react';
import MainPage from './MainPage';
import "./App.css"
import ExchangePage from './exchangePage';

function App() {

  const [currentPage, setCurrentPage] = useState('main');
  const [buttonId, setButtonId] = useState(null);

  const switchToExchangePage = (id) => {
    setButtonId(id);
    setCurrentPage('exchange');
  };

  const goBackToMainPage = () => {
    setCurrentPage('main');
  };

  return (
    <div className="App">
      {currentPage === 'main' ? (
        <MainPage onSwitchPage={switchToExchangePage} />
      ) : (
        <ExchangePage buttonId={buttonId} onGoBack={goBackToMainPage} />
      )}
    </div>
  );
}

export default App;